<script>
  import { selectedTag } from '../store.js';
  export let componentData = [];
  export let dividerAbove = false;
  export let dividerBelow = false;

  const BASE_URL = import.meta.env.PUBLIC_BASE_URL;
const BASE_SELF_URL = import.meta.env.PUBLIC_BASE_SELF_URL;


  function convertToURLSlug(slug) {
    if(!slug) { return '' }
    slug = handleSpecialCharacters(slug);

    return slug.split(' ').map(function(word){
      return word.toLowerCase();
    }).join('-').replace(/[^a-zA-Z0-9-]/g, '');
  }

  function handleSpecialCharacters(str) {
    if(!str){ return '' }
    let map = {
      'å': 'a',
      'ä': 'a',
      'ö': 'o',
      // option to add more special characters here
    };
    return str.replace(/[åäö]/g, function(match) {
      return map[match];
    });
  }


  let articles = null;
  if(componentData && componentData.length > 0){
    let slugParsed = '';
    let slugPrefix = '';
    let articleCategory;
    articles = componentData.map(item => {
      console.log('item locale', item.attributes.locale)

      slugParsed = convertToURLSlug(item.attributes.Title);
      slugPrefix = item.attributes.locale === 'en' ? '/en/rooms/' : '/lokaler/';
      articleCategory = item.attributes.kontor_kategorier?.data?.attributes.Category || '';
      return {
        title: item.attributes.Title,
        subtitle: articleCategory,
        text: item.attributes.Text || '',
        ingress: item.attributes.Ingress || '',
        link: slugPrefix + slugParsed || '#',
        imgSrc: item.attributes.Image?.data?.attributes?.url ? BASE_URL + item.attributes.Image.data.attributes.url : ''      }
    });



  }else{
    articles = [];
  }

  let currentPage = 1;
  let itemsPerPage = 9;


  $: filteredArticles = $selectedTag !== "" && $selectedTag !== null && $selectedTag !== undefined // reactive statement
          ? articles.filter(article => article.subtitle === $selectedTag)
          : articles;

  $: if ($selectedTag !== "" && $selectedTag !== null && $selectedTag !== undefined) {
    currentPage = 1;
  }

  $: numberOfPages = Math.ceil(filteredArticles.length / itemsPerPage);
  $: paginatedArticles = filteredArticles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

</script>


<style>
  .hidden {
    display: none;
  }
</style>

<section class="section"
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>


  <div class="wrapper">
    <div class="grid grid--3">
      {#each paginatedArticles as article}
        <a href="{article.link}" class="post-card">
          <div class="post-card__bg">
            <img width="1920" height="1080" src="{article.imgSrc}" alt="{article.title}" loading="lazy" class="not-in-use-now-is-loading">
          </div>
          <div class="post-card__content">
            <h6>{article.subtitle}</h6>
            <h2>{@html article.title}</h2>
            <p>{@html article.ingress}</p>
            <span class="btn btn--outline">Read more</span>
          </div>
        </a>
      {/each}
    </div>

    <nav class="pagination {numberOfPages <= 1 ? 'hidden' : ''}">
      <a href="#"
         class="pagination__prev {currentPage === 1 ? 'disabled' : ''}"
         on:click={e => { e.preventDefault(); currentPage > 1 && currentPage-- }}>
        <svg class="icon icon-arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
          <polygon points="28.4 16.5 17.59 27.29 19.89 29.59 33 16.5 19.89 3.41 17.59 5.71 28.4 16.5"/>
          <polyline points="28.62 14.88 0 14.87 0 18.12 28.62 18.13"/>
        </svg>
      </a>
      <a href="#">{currentPage}</a>
      <span>/</span>
      <a href="#">{numberOfPages}</a>
      <a href="#"
         class="pagination__next {currentPage === numberOfPages ? 'disabled' : ''}"
         on:click={e => { e.preventDefault(); currentPage < numberOfPages && currentPage++ }}>
        <svg class="icon icon-arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
          <polygon points="28.4 16.5 17.59 27.29 19.89 29.59 33 16.5 19.89 3.41 17.59 5.71 28.4 16.5"/>
          <polyline points="28.62 14.88 0 14.87 0 18.12 28.62 18.13"/>
        </svg>
      </a>
    </nav>
  </div>    
</section>
